


















import { RouterNames } from "@/router/routernames";
import { proyecto } from "@/shared/dtos/proyecto";
import proyectoModule from "@/store/modules/proyecto-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
    KanbanList: () => import("@/components/TypeView/Kanban_List.vue"),
  },
})
export default class ProyectoLista extends Vue {
  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(obj: proyecto) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: proyecto) {
    proyectoModule.eliminarproyecto(obj);
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.proyectosformulario,
      params: { id: id },
    });
  }
  to_link() {
    this.$router.push({ name: RouterNames.proyectoskanban });
  }
}
